import React, { ReactNode } from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { useNavigate } from '@reach/router'

import { breakpoints } from 'src/ui/dimensions'
import { Header, Nav } from 'src/ui/organisms'
import { styled } from 'src/ui/theme'

import { MenuButton, ExternalMenu } from './../molecules'

const DesktopMenuWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  justify-content: flex-end;
`

type Props = {
  withoutNavShadow?: boolean
  AdditionalComponent?: ReactNode
}

export const PageHeader: React.FC<Props> = ({
  withoutNavShadow,
  AdditionalComponent,
}) => {
  const menuItems = useStaticQuery<GatsbyTypes.NavItemsQuery>(
    graphql`
      query NavItems {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  )

  const externalLinks = [
    {
      name: 'Портфолио',
      url: 'https://kode.ru/projects',
    },
    {
      name: 'Награды',
      url: 'https://kode.ru/awards',
    },
    {
      name: 'Карьера у нас',
      url: '/',
      active: true,
    },
    {
      name: 'Новости',
      url: 'https://vk.com/appkode',
      target: '_blank',
    },
  ]

  const navigate = useNavigate()

  const [menuOpen, setMenuOpen] = React.useState(false)
  /* 
  prevent scroll content on mobile safari
*/
  React.useEffect(() => {
    const el = document.getElementById('___gatsby')
    if (menuOpen) {
      if (el) {
        el.style.position = 'fixed'
        el.style.overflow = 'hidden'
        el.style.height = '90vh'
        el.style.width = '100%'
      }
    } else {
      if (el) {
        el.style.position = 'initial'
        el.style.overflow = 'visible'
        el.style.height = 'auto'
        el.style.width = 'auto'
      }
    }
  }, [menuOpen])

  React.useEffect(() => {
    const el = document.getElementById('___gatsby')
    return () => {
      if (el) {
        el.style.position = 'initial'
        el.style.overflow = 'visible'
        el.style.height = 'auto'
      }
    }
  }, [])
  const NavComponent =
    (menuOpen && menuItems.site?.siteMetadata?.menuLinks) ||
    (typeof window !== 'undefined' &&
      window.innerWidth >= breakpoints.tablet &&
      menuItems.site?.siteMetadata?.menuLinks) ? (
      <Nav
        withoutShadow={withoutNavShadow}
        items={menuItems.site.siteMetadata.menuLinks.reduce<
          {
            value: string
            label: string
            active: boolean
          }[]
        >(
          (acc, item) => {
            if (item && item.name && item.link) {
              if (typeof location === 'undefined') {
                return acc
              }
              return [
                ...acc,
                {
                  label: item.name,
                  value: item.link,
                  active: Boolean(location.pathname === item.link),
                },
              ]
            } else {
              return acc
            }
          },

          []
        )}
      ></Nav>
    ) : null
  return (
    <>
      <Header onLogoPress={() => navigate('https://kode.ru')}>
        {typeof window !== 'undefined' &&
        window.innerWidth < breakpoints.tablet ? (
          <MenuButton
            onPress={() => setMenuOpen(!menuOpen)}
            active={menuOpen}
          />
        ) : (
          <DesktopMenuWrapper>
            <ExternalMenu links={externalLinks} />
          </DesktopMenuWrapper>
        )}
      </Header>
      {window.innerWidth < breakpoints.tablet ? NavComponent : null}
      {AdditionalComponent}
      {window.innerWidth >= breakpoints.tablet ? NavComponent : null}
    </>
  )
}
